<template>
  <div class="base" style="width: 100%; height: 100%">
    <transition name="el-fade-in-linear">
      <div class="whole_wrap" v-show="show.wholeShow">
        <div class="head_wrap"></div>
        <div class="body_wrap">
          <div class="target_content">
            <el-card shadow="always">
              <div class="target_top_content">
                <span class="countTarget"
                  >{{ pageData.targetList.length
                  }}{{ $t("target.span_counttarget") }}</span
                >
                <span>
                  <el-button size="small" type="primary" @click="addTarget"
                    >{{ $t("target.btn_addtarget") }}
                  </el-button>
                </span>
                <span>
                  <el-button
                    class="exportTemplate"
                    type="primary"
                    size="small"
                    @click="exportTemplate"
                    >{{ $t("target.btn_exporttp") }}</el-button
                  >
                </span>
                <el-dialog
                  width="20%"
                  :title="$t('target.title_add_target')"
                  v-model="pageData.targetAdderVisible"
                  ref="targetAdder"
                  :modal="true"
                  :close-on-click-modal="false"
                  :close-on-press-escape="false"
                  :center="true"
                >
                  <el-form
                    :model="formData.newTarget"
                    ref="newTargetForm"
                    class="newTargetForm"
                    size="mini"
                  >
                    <el-form-item :label="$t('target.label_target')">
                      <el-input
                        v-model="formData.newTarget.name"
                        maxlength="70"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="$t('target.label_target_en')">
                      <el-input
                        v-model="formData.newTarget.name_en"
                        maxlength="70"
                        show-word-limit
                        size="medium"
                        :placeholder="$t('text.placeholder')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <template #footer>
                    <span>
                      <el-button
                        class="addTargetBtn"
                        type="success"
                        size="medium"
                        @click="doAddTarget(formData.newTarget)"
                        :loading="pageData.doAddTargetLoading"
                        >{{ $t("target.btn_save") }}</el-button
                      >
                    </span>
                  </template>
                </el-dialog>
              </div>
              <div class="target_body_content">
                <el-table
                  stripe
                  border
                  ref="targetTable"
                  :header-cell-style="{
                    textAlign: 'center',
                    backgroundColor: '#f2f6fc',
                  }"
                  :cell-style="{ textAlign: 'center' }"
                  :data="pageData.targetList"
                >
                  <el-table-column :label="$t('target.label_target')">
                    <template #default="scope">
                      <span v-if="scope.row.id === pageData.currentClickId">
                        <el-input
                          size="mini"
                          v-model="scope.row.name"
                          class="input_items"
                          maxlength="70"
                          show-word-limit
                        ></el-input>
                      </span>
                      <span v-else>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('target.label_target_en')">
                    <template #default="scope">
                      <span v-if="scope.row.id === pageData.currentClickId">
                        <el-input
                          size="mini"
                          v-model="scope.row.name_en"
                          class="input_items"
                          maxlength="70"
                          show-word-limit
                        ></el-input>
                      </span>
                      <span v-else>{{ scope.row.name_en }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :label="$t('target.label_options')">
                    <template #default="scope">
                      <el-button
                        circle
                        class="el-icon-edit"
                        type="primary"
                        size="small"
                        @click="modifyTarget(scope.row)"
                        v-if="scope.row.id !== pageData.currentClickId"
                      ></el-button>
                      <el-button
                        circle
                        class="el-icon-check"
                        type="success"
                        size="small"
                        @click="confirmModifyTarget(scope.row)"
                        v-else
                      ></el-button>

                      <el-popconfirm
                        :title="$t('text.message_delete_confirm')"
                        @confirm="deleteTarget(scope.row)"
                      >
                        <template #reference>
                          <el-button
                            circle
                            class="el-icon-delete"
                            type="danger"
                            size="small"
                          ></el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-card>
          </div>
        </div>
        <div class="foot_wrap"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import * as utilsjs from '@/assets/js/utils'
import * as mountederjs from '@/assets/js/mounteder'
export default {
  data() {
    return {
      show: {
        wholeShow: false
      },
      pageData: {
        username: '',
        userinfo: {},
        lang: '',
        targetList: [],
        targetList_xlsx: [],
        currentClickId: null,
        oldTarget: '',
        targetAdderVisible: false,
        doAddTargetLoading: false
      },
      formData: {
        newTarget: {
          name: '',
          name_en: ''
        }
      }
    };
  },
  mounted() {
    // document.title = this.$t('title.');
    this.pageData.username = sessionStorage.getItem('username');
    this.pageData.userinfo = JSON.parse(sessionStorage.getItem(this.pageData.username));
    this.pageData.lang = sessionStorage.getItem('lang');
    if (mountederjs.evaluateM(this)) {
      this.getTarget();
      setTimeout(() => { this.show.wholeShow = true }, 1);
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    getTarget() {
      let params = {
        user: {}
      };
      params.user.username = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/queryQuestionTargets',
        data: params
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {

            this.pageData.targetList = response.data.data;

            for (let target of this.pageData.targetList) {
              let tmp = {};
              // tmp.id = target.id;
              tmp.name = target.name;
              tmp.name_en = target.name_en;
              this.pageData.targetList_xlsx.push(tmp);
            }

            return;
          } else if (response.data.code == '210') {
            this.$notify.warning({
              title: this.$t("notify.title_server"),
              message: this.$t('question.notify_questiontargetnull')
            });
            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    exportTemplate() {
      const template_workbook = XLSX.utils.book_new();
      const template_worksheet = XLSX.utils.json_to_sheet(this.pageData.targetList_xlsx);
      XLSX.utils.book_append_sheet(template_workbook, template_worksheet, 'Indicators');
      XLSX.writeFile(template_workbook, 'Indicator_Template.xlsx');
    },
    addTarget() {
      this.pageData.targetAdderVisible = true;
    },
    doAddTarget(target) {
      this.pageData.doAddTargetLoading = true;

      for (let index in target) {
        if (utilsjs.strIsEmpty(target[index])) {
          this.pageData.doAddTargetLoading = false;
          this.$notify.error({
            title: this.$t("common.notity_title_message"),
            message: this.$t('target.notify_incompletely')
          });
          return;
        } else {
          continue;
        }
      }

      //set values
      target.creator = this.pageData.username;
      target.reviser = this.pageData.username;

      //post
      this.postToAddTarget(target);
    },
    postToAddTarget(target) {
      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/createQuestionTarget',
        data: target
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_saved"),
              type: 'success'
            });

            this.pageData.doAddTargetLoading = false;
            this.pageData.targetAdderVisible = false;

            setTimeout(() => {
              location.reload();
            }, 800)
            return;
          } else {
            //code = 700 and more
            this.pageData.doAddTargetLoading = false;
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.pageData.doAddTargetLoading = false;
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.pageData.doAddTargetLoading = false;
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    deleteTarget(target) {
      target.reviser = this.pageData.username;

      this.$axios({
        method: 'post',
        url: this.$api + '/api/evaluate/deleteQuestionTarget',
        data: target
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code == '200') {
            this.$ElMessage.success({
              message: this.$t("text.message_deleted"),
              type: 'success'
            });

            for (let index in this.pageData.targetList) {
              if (this.pageData.targetList[index].id === target.id) {
                this.pageData.targetList.splice(index, 1);
              }
            }

            return;
          } else {
            //code = 700 and more
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          }
        } else {
          this.$notify.error({
            title: this.$t('notify.title_server'),
            message: this.$t('notify.content_server') + response.status
          });
          return;
        }
      }).catch((error) => {
        this.$notify.error({
          title: this.$t("notify.title_server"),
          message: this.$t('notify.content_refused')
        });
        return;
      });
    },
    modifyTarget(target) {
      this.pageData.currentClickId = target.id;
      this.pageData.oldTarget = '';
      this.pageData.oldTarget = JSON.parse(JSON.stringify(target));
    },
    confirmModifyTarget(target) {
      if (target.name !== this.pageData.oldTarget.name || target.name_en !== this.pageData.oldTarget.name_en) {
        if (utilsjs.strIsEmpty(target.name) || utilsjs.strIsEmpty(target.name_en)) {
          //incomplete
          return;
        } else {
          //save changes
          target.reviser = this.pageData.username;

          //post
          this.$axios({
            method: 'post',
            url: this.$api + '/api/evaluate/reviseQuestionTarget',
            data: target
          }).then((response) => {
            if (response.status == 200) {
              if (response.data.code == '200') {
                this.$ElMessage.success({
                  message: this.$t("text.message_saved"),
                  type: 'success'
                });

                this.pageData.currentClickId = null;
                this.pageData.oldTarget = null;
                return;
              } else {
                //code = 700 and more
                this.$notify.error({
                  title: this.$t("notify.title_server"),
                  message: this.$t('notify.content_refused')
                });
                return;
              }
            } else {
              this.$notify.error({
                title: this.$t('notify.title_server'),
                message: this.$t('notify.content_server') + response.status
              });
              return;
            }
          }).catch((error) => {
            this.$notify.error({
              title: this.$t("notify.title_server"),
              message: this.$t('notify.content_refused')
            });
            return;
          });
        }
      } else {
        //no change
        this.pageData.currentClickId = null;
        this.pageData.oldTarget = null;

        this.$ElMessage.warning({
          message: this.$t("text.message_nochange"),
          type: 'success'
        });
        return;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.target_content {
  margin-top: 10px;
  padding: 20px;
}
.countTarget {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  color: #f56c6c;
}
.target_top_content .el-button {
  float: right;
  margin-bottom: 20px;
  margin-left: 10px;
}
.addTargetBtn {
  margin-top: -30px;
  float: right;
}
</style>